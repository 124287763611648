@import "../../assets/styles/fonts";
@import "../../assets/styles/colors";



.login_center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.login_window {
  position: fixed;
  margin-left: 5%;
}

.login_window__mobile_warning {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.login_window__modal {
  // padding-bottom: 20px;
  min-width: 250px;
  width: 90%;
  bottom: 20%;
  background-color: $bg_color--lightdark;
  min-height: 100px;
  border-radius: 10px;
  transition: box-shadow .3s;
  z-index: 1;
  position: fixed;
  box-shadow: 0 0 11px rgba(33,33,33,.2); 

  &--mobile {
    @extend .login_window__modal;
    bottom: 10%;
  }

  &--image {
    @extend .login_window__modal;
    margin: 0px;
    height: 100px;
    border-radius: 10px;
    // border-bottom-left-radius: 0px;
    // border-bottom-right-radius: 0px;
    background-color: $bg_color--lightdark;
    object-fit: scale-down;
  }


  &--add {
    text-align: center;
    // height: 100px;

    .plus {
      --b: 4px; /* the thickness */
      width: 30px; /* the size */
      aspect-ratio: 1;
      border: 10px solid #000; /* the outer space */
      background:
        conic-gradient(from 90deg at var(--b) var(--b),#000 90deg,#fff 0) 
        calc(100% + var(--b)/2) calc(100% + var(--b)/2)/
        calc(50%  + var(--b))   calc(50%  + var(--b));
      display: inline-block;
    }
    
    .alt {
      border: none;
      margin: 30px;
      background:
        conic-gradient(from 90deg at var(--b) var(--b),$bg_color--lightdark 90deg,#000 0) 
        calc(100% + var(--b)/2) calc(100% + var(--b)/2)/
        calc(50%  + var(--b))   calc(50%  + var(--b));
    }
  }

  
  &--elements {
    font-family: $font_family-montserrat;
    color: $bg_color--dark;
    margin-top: 10px;
    margin-left: 10px;
    margin-bottom: 15px;
    word-break: break-word;


    &--progress_bar{
      margin-top: 20px;
    }

    &--header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-right: 10px;
      
    }

    h3 {
      font-size: 20px;
      margin-block-start: 0em;
      margin-block-end: 0em;
    }

    h4 {
      font-size: 14px;
      margin-block-start: 0em;
      margin-block-end: 0em;
    }

    .description {
      font-size: 14px;
    }

    .instagam {
      font-size: 12px;
    }

    a {
      font-size: 14px;
      margin-block-start: 10px;
      margin-block-end: 0em;
    }

    p {
      font-size: 12px;
      margin-block-start: 10px;
      margin-block-end: 0em;
    }

    ol {
      font-size: 14px;
      margin-block-start: 10px;
      margin-block-end: 0em;

      li {
        margin-block-start: 6px;
        margin-block-end: 0em;
      }
    }

    input {
      margin-top: 5px;
      font-size: 14px;
      width: 90%;
    }
  }

  &--element{
    @extend .login_window__modal--elements;
    padding-top: 0px;
  }


  &:hover {
    box-shadow: 0 0 11px rgba(33,33,33,.4); 
  }
}


.login_window__card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 20px;
  margin-bottom: 10px;
  padding-bottom: 20px;
  width: 95%;
  
  // background: greenyellow;
  /* From https://css.glass */
  background: rgba(255, 0, 0, 0.23);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.22);

  h3 {
      color: $bg_color--dark;
  }
}


.vertical_layout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.horizontal_layout {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.small_img {
  width: 50px;
  height: 50px;
}