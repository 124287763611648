@import "../../assets/styles/colors";
@import "../../assets/styles/fonts";

.Report {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: space-between;
    // flex-wrap: wrap;

    &-demo {
        @extend .Report;
        height: 80vh;
    }

    &-sidebar {
        

        background-color: #ADFF2F;
        background-color: $bg_color--lightdark;
        // margin-left: 5px;
        // margin-right: 5px;
        // padding-left: 5px;
        // padding-right: 5px;
        // padding: 20px;
        margin: 10px;
        margin-top: 100px;
        padding: 10px;
        border-radius: 30px;
        width: 300px;
        width: 300px;

        &-element {
            display: flex;
            padding-left: 10px;
            padding-right: 10px;
            align-content: center;
            min-height: 50px;
            color: #3f3f3f;
            border-radius: 10px;

            &_selected {
                @extend .Report-sidebar-element;
                color: #000;
                background-color: #86e000;
            }
        }
    }
    
    &-center {
        margin-top: 100px;

        padding: 10px;
        // border: 1px solid black;
        // max-width: 500px;
        // width: 100%;
        min-width: 200px;
    }
}

.dropdown_icon {
    width: 20px;
    height: 20px;
}

.close_icon {
    width: 30px;
    height: 30px;
}


// @media only screen and (max-width: 600px) {
.Report-mobile {
    height: 100vh;
    width: 100%;
    display: flex;
    // padding-bottom: 100px;
    justify-content: space-between;
    flex-direction: column;
    // flex-wrap: wrap;

    &-demo {
        @extend .Report-mobile;
        height: 80vh;
    }

    &-topbar {
        width: 100%;
        
        
        

        &-header {
            position: fixed;
            background-color: white;
            width: 100%;
            display: flex;
            height: 80px;
            flex-direction: row;
            align-items: center;
            justify-content: start;
            // border-bottom: 1px solid black;
            background-color: #ADFF2F;

            a:link { 
                text-decoration: none; 
            } 
            a:visited { 
                text-decoration: none; 
            } 
            a:hover { 
                text-decoration: none; 
            } 
            a:active { 
                text-decoration: none; 
            }
            

            &-element_horizontal{
                display: flex;
                flex-direction: row;
                align-items: center;
            }
            
            &-element { 
                padding-left: 10px;
                padding-right: 10px;
                color: $font_color--dark;
            }
        }

        &-collabsable {
            position: fixed;
            top: 60px;
            width: 100%;
            background-color: #ADFF2F;
            border-radius: 0px 0px 10px 10px;

            // ADFF2F
        }

        &-element {
            display: flex;
            align-content: center;
            min-height: 50px;
            padding-left: 10px;
            padding-right: 10px;
            color: #3f3f3f;

            &_selected {
                display: flex;
                align-content: center;
                min-height: 50px;
                padding-left: 10px;
                padding-right: 10px;
                color: #000;
                background-color: #86e000;
                // text-decoration: blue wavy underline;
            }
        }
    }

    &-bottombar {
        min-height: 80px;
        // min-width: 200px;
        // max-width: 280px;
        width: 100%;
        background-color: white;
        position: fixed;
        bottom: 0px;
        background-color: #fefefe;
        border-top: 1px solid black;
        border-radius: 10px 10px 0px 0px;

        &-header {
            
            padding-left: 10px;
            padding-right: 10px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }

        &-element {
            padding-left: 10px;
            padding-right: 10px;

            color: black;

            &_selected {
                color: blue;
            }
        }
    }

    &-bottombar_stub {
        min-height: 80px;
        // min-width: 200px;
        // max-width: 280px;
        width: 100%;
        background-color: white;
        color: white;
        bottom: 0px;

        &-header {
            
            padding-left: 10px;
            padding-right: 10px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }

        &-element {
            padding-left: 10px;
            padding-right: 10px;

            color: black;

            &_selected {
                color: blue;
            }
        }
    }
    
    &-center {
        // border: 1px solid black;
        // max-width: 500px;
        width: 100%;
        min-width: 200px;
        margin-top: 80px;
        margin-bottom: 0px;
        padding-bottom: 0px;
    }
}
// }