@import "../../assets/styles/colors";
@import "../../assets/styles/fonts";


.card_spine {
  font-family: $font_family-montserrat;
  font-weight: $font_weight-regular;
  border: 0;
  width: 100%;
  height: 30px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  // padding-right: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 1;
  color: white;

  p {
    margin-right: 10px;
    pointer-events: none;
  }

  // theme:

  &--red {
    @extend .card_spine;
    background-color: $additional_color--red;
  }

  &--orange {
    @extend .card_spine;
    background-color: $additional_color--orange;
  }

  &--blue {
    @extend .card_spine;
    background-color: $additional_color--blue;
  }

  &--green {
    @extend .card_spine;
    background-color: $additional_color--green;
  }

  &--violet {
    @extend .card_spine;
    background-color: $additional_color--violet;
  }

  &--gray {
    @extend .card_spine;
    background-color: $additional_color--gray;
  }

}



