$color--primary: #94BA7D;
$color--primary-accent: #73AC50;
$color--primary-disabled: #BFDFAC;

$color--error: #f6b0aa;
$color--error-accent: #dfc2c0;
$color--error-disabled: #bf8884;

$color--secondary: #B2D2DD;
$color--secondary-accent: #83BDD1;
$color--secondary-disabled: #E5E9EB;

$color--ternary: #FFF2B5;
$color--ternary-accent: #EFD973;
$color--ternary-disabled: #F4EDCB;


$bg_color--light: #FAFAFA;
$bg_color--layer: #E0E0E0;
$bg_color--lightdark: #F0F0F0;
$bg_color--highlight: #86e000;
$bg_color--gray: #A0A0A0;
$bg_color--dark: #343434;


$shadow_color--light: #e8e8e8;


$font_color--light: #FAFAFA;
$font_color--dark: #343434;
$font_color--gray: #AFAFAF;



$additional_color--red: #D33F49;
$additional_color--orange: #E27D60;
$additional_color--light-blue: #9bbbdb;
$additional_color--green: #379683;
$additional_color--light-green: #90d8ca;
$additional_color--violet: #B57CEE;
$additional_color--gray: #C4C4C4;
$additional_color--blue: #005DE9;
