@import "../../assets/styles/colors";
@import "../../assets/styles/fonts";


.label_picker {
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.label_picker--item{
  
  &--selected {
    background-color: $color--primary;
  }
}