@import "../../assets/styles/colors";
@import "../../assets/styles/fonts";


.card_menu_modal {
  width: 200px;
  background-color: $bg_color--lightdark;
  min-height: 100px;
  border-radius: 10px;
  transition: box-shadow .3s;
  z-index: 1;
  position: relative;
  box-shadow: 0 0 11px rgba(33,33,33,.2); 

  font-family: $font_family-montserrat;
  color: $bg_color--dark;
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 15px;
  word-break: break-word;

  &--header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 10px;
  }

  &--anchor {
    // position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 10px;
  }

  &--items {
    margin: 10px;
  }

  &--item {
    margin-top: 4px;
    margin-bottom: 10px;
    border-top: 1px solid $bg_color--gray;
  }

  h3 {
    font-size: 16px;
    margin-block-start: 0em;
    margin-block-end: 0em;
  }

  .description {
    font-size: 14px;
  }

  a {
    font-size: 14px;
    margin-block-start: 10px;
    margin-block-end: 0em;
  }

  p {
    font-size: 14px;
    margin-block-start: 6px;
    margin-block-end: 0em;
    margin-left: 10px;
  }

  &--header {
    display: flex;
    padding-top: 10px;
    padding-right: 10px;
    flex-direction: row;
    justify-content: flex-end;
  }

  &:hover {
    box-shadow: 0 0 11px rgba(33,33,33,.4); 
  }


  
  
  
}



