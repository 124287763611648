@import "../../assets/styles/fonts";
@import "../../assets/styles/colors";

.card {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 20px;
  margin-bottom: 10px;
  padding-bottom: 0px;
  width: 300px;

  &--max_width {
    width: 100%;
    margin-left: 10%;
    margin-right: 10%;
  }

  &--width_80 {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
  }

  &--nomargin {
    margin-left: 0%;
    margin-right: 0%;
    margin-bottom: 0px;
  }
  background-color: $bg_color--lightdark;
  min-height: 100px;
  border-radius: 10px;
  transition: box-shadow .3s;

  &--padding {
    padding-bottom: 20px;
  }

  &--relative {
    position: relative;
    z-index: 2;
  }

  &--image {
    @extend .card;
    margin: 0px;
    height: 100px;
    border-radius: 10px;
    // border-bottom-left-radius: 0px;
    // border-bottom-right-radius: 0px;
    background-color: $bg_color--lightdark;
    object-fit: scale-down;
  }


  &--add {
    text-align: center;
    // height: 100px;

    .plus {
      font-size: 40px;
    }

    // .plus {
    //   --b: 4px; /* the thickness */
    //   width: 30px; /* the size */
    //   aspect-ratio: 1;
    //   border: 10px solid #000; /* the outer space */
    //   background:
    //     conic-gradient(from 90deg at var(--b) var(--b),#000 90deg,#fff 0) 
    //     calc(100% + var(--b)/2) calc(100% + var(--b)/2)/
    //     calc(50%  + var(--b))   calc(50%  + var(--b));
    //   display: inline-block;
    // }
    
    // .alt {
    //   border: none;
    //   margin: 30px;
    //   background:
    //     conic-gradient(from 90deg at var(--b) var(--b),$bg_color--lightdark 90deg,#000 0) 
    //     calc(100% + var(--b)/2) calc(100% + var(--b)/2)/
    //     calc(50%  + var(--b))   calc(50%  + var(--b));
    // }
  }


  
  
  &--text {
    
    color: $bg_color--dark;
    margin: 10px;
    word-break: break-word;
    // pointer-events: none;
    pre {
      font-family: $font_family-montserrat;
      white-space: pre-wrap;       /* Since CSS 2.1 */
      white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
      white-space: -pre-wrap;      /* Opera 4-6 */
      white-space: -o-pre-wrap;    /* Opera 7 */
      word-wrap: break-word;       /* Internet Explorer 5.5+ */
    }

    &--unclickable {
      pointer-events: none;
      
      &--no-margin {
        margin: 0px;
      }
    }
    

    h3 {
      font-size: 18px;
      margin-block-start: 0em;
      margin-block-end: 0em;
    }

    .description {
      font-size: 14px;
    }

    .under_development {
      font-size: 14px;
    }

    .instagram {
      font-size: 12px;
    }

    a {
      font-size: 14px;
      margin-block-start: 10px;
      margin-block-end: 0em;
    }

    p {
      font-size: 14px;
      margin-block-end: 0em;
    }
  }


  &:hover {
    box-shadow: 0 0 11px rgba(33,33,33,.2); 
  }
}

