@import "../../assets/styles/colors";
@import "../../assets/styles/fonts";


.Reports {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: space-between;
    // flex-wrap: wrap;

    &-sidebar {
        background-color: #ADFF2F;
        // margin-left: 5px;
        // margin-right: 5px;
        padding-left: 5px;
        padding-right: 5px;
        min-width: 200px;
        max-width: 280px;

        &-element {
            display: flex;
            align-content: center;
            min-height: 50px;
            color: #3f3f3f;

            &_selected {
                display: flex;
                align-content: center;
                min-height: 50px;
                color: #000;
                background-color: #86e000;
            }
        }
    }
    
    &-center {
        border: 1px solid black;
        // max-width: 500px;
        width: 100%;
        min-width: 200px;

        &--inputfile {
            width: 0.1px;
            height: 0.1px;
            opacity: 0;
            overflow: hidden;
            position: absolute;
            z-index: -1;
      
            &--label {
              display: block;
              margin-top: 20px;
              max-width: 400px;
            }
      
            &--chosen_file {
              margin-top: 10px;
              color: $additional_color--blue;
            }
      
            &--error_msg {
              color: $additional_color--red;
            }
        }
    }
}

.dropdown_icon {
    width: 20px;
    height: 20px;
}

.close_icon {
    width: 30px;
    height: 30px;
}

// @media only screen and (max-width: 600px) {
.Reports-mobile {
    height: 100vh;
    width: 100%;
    display: flex;
    // padding-bottom: 100px;
    justify-content: space-between;
    flex-direction: column;
    // flex-wrap: wrap;

    &-topbar {
        width: 100%;
        
        
        

        &-header {
            position: fixed;
            background-color: white;
            width: 100%;
            display: flex;
            min-height: 60px;
            flex-direction: row;
            align-items: center;
            justify-content: start;
            // border-bottom: 1px solid black;
            background-color: #ADFF2F;

            &-element_horizontal{
                display: flex;
                flex-direction: row;
                align-items: center;
            }
            
            &-element { 
                padding-left: 10px;
                padding-right: 10px;
            }
        }

        &-collabsable {
            position: fixed;
            top: 60px;
            width: 100%;
            background-color: #ADFF2F;
            border-radius: 0px 0px 10px 10px;

            // ADFF2F
        }

        &-element {
            display: flex;
            align-content: center;
            min-height: 50px;
            padding-left: 10px;
            padding-right: 10px;
            color: #3f3f3f;

            &_selected {
                display: flex;
                align-content: center;
                min-height: 50px;
                padding-left: 10px;
                padding-right: 10px;
                color: #000;
                background-color: #86e000;
                // text-decoration: blue wavy underline;
            }
        }
    }

    &-bottombar {
        min-height: 80px;
        // min-width: 200px;
        // max-width: 280px;
        width: 100%;
        background-color: white;
        position: fixed;
        bottom: 0px;
        background-color: #fefefe;
        border-top: 1px solid black;
        border-radius: 10px 10px 0px 0px;

        &-header {
            
            padding-left: 10px;
            padding-right: 10px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }

        &-element {
            padding-left: 10px;
            padding-right: 10px;

            color: black;

            &_selected {
                color: blue;
            }
        }
    }

    &-bottombar_stub {
        min-height: 80px;
        // min-width: 200px;
        // max-width: 280px;
        width: 100%;
        background-color: white;
        color: white;
        bottom: 0px;

        &-header {
            
            padding-left: 10px;
            padding-right: 10px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }

        &-element {
            padding-left: 10px;
            padding-right: 10px;

            color: black;

            &_selected {
                color: blue;
            }
        }
    }
    
    &-center {
        // border: 1px solid black;
        // max-width: 500px;
        width: 100%;
        min-width: 200px;
        margin-top: 60px;
        margin-bottom: 0px;
        padding-bottom: 0px;

        &-element {
            padding: 20px;
            margin: 10px;
            display: flex;
            border-radius: 30px;
            align-items: center;
            flex-direction: row;
            justify-content: space-between;

            background-color: $bg_color--lightdark;

            color: black;

            &_selected {
                background-color: #86e000;
            }

            &_highlighted {
                @extend .Reports-mobile-center-element;
                background-color: $bg_color--highlight;
                margin-top: 20px;
            }

            &-error {
                @extend .Reports-mobile-center-element;
                background-color: $additional_color--red;
                color: $font_color--light;
            }
        }
    }
}
// }