@import "../../assets/styles/fonts";
@import "../../assets/styles/colors";

.modal {
  // padding-bottom: 20px;
  width: 95vw;
  min-height: 100px;
  border-radius: 10px;
  transition: box-shadow .3s;
  z-index: 1;
  position: absolute;
  margin-bottom: 80px;
  padding-bottom: 100px;

  form {
    padding-top: 10px;
    border-radius: 10px;
    background-color: $bg_color--lightdark;
  }
  // background-color: $bg_color--lightdark;
  

  &--image {
    @extend .modal;
    margin: 0px;
    height: 100px;
    border-radius: 10px;
    // border-bottom-left-radius: 0px;
    // border-bottom-right-radius: 0px;
    background-color: $bg_color--lightdark;
    object-fit: scale-down;
  }


  &--add {
    text-align: center;
    // height: 100px;

    .plus {
      --b: 4px; /* the thickness */
      width: 30px; /* the size */
      aspect-ratio: 1;
      border: 10px solid #000; /* the outer space */
      background:
        conic-gradient(from 90deg at var(--b) var(--b),#000 90deg,#fff 0) 
        calc(100% + var(--b)/2) calc(100% + var(--b)/2)/
        calc(50%  + var(--b))   calc(50%  + var(--b));
      display: inline-block;
    }
    
    .alt {
      border: none;
      margin: 30px;
      background:
        conic-gradient(from 90deg at var(--b) var(--b),$bg_color--lightdark 90deg,#000 0) 
        calc(100% + var(--b)/2) calc(100% + var(--b)/2)/
        calc(50%  + var(--b))   calc(50%  + var(--b));
    }
  }


  .fixed_height_container
  {
    height: 80%;
  }
  .scrollable
  {
    height: 80%;
    overflow:auto;
  }
  
  &--elements {
    font-family: $font_family-montserrat;
    color: $bg_color--dark;
    margin-top: 10px;
    margin-left: 10px;
    margin-bottom: 15px;
    word-break: break-word;

    
    &--header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-right: 10px;
    }

    h3 {
      font-size: 20px;
      margin-block-start: 0em;
      margin-block-end: 0em;
    }

    h4 {
      font-size: 14px;
      margin-block-start: 0em;
      margin-block-end: 0em;
    }

    .description {
      font-size: 14px;
    }

    .instagam {
      font-size: 12px;
    }

    a {
      font-size: 14px;
      margin-block-start: 10px;
      margin-block-end: 0em;
    }

    p {
      font-size: 12px;
      margin-block-start: 10px;
      margin-block-end: 0em;
    }

    input {
      margin-top: 5px;
      font-size: 14px;
      width: 90%;
    }

    textarea {
      margin-top: 5px;
      font-size: 14px;
      width: 90%;
      min-height: 60px;
    }
  }

  &--element{
    @extend .modal--elements;
    padding-top: 0px;

    .hint {
      p {
        font-size: 14px;
        margin-block-start: 5px;
        margin-block-end: 0em;
      }
      
    }
  }

  &-input {
    font-family: $font_family-montserrat;
    font-weight: $font_weight-regular;
  }

  &--textarea {
    font-family: $font_family-montserrat;
    font-weight: $font_weight-regular;
  }


  &:hover {
    box-shadow: 0 0 11px rgba(33,33,33,.2); 
  }
}

