@import "../../assets/styles/colors";
@import "../../assets/styles/fonts";


.card_button {
  font-family: $font_family-montserrat;
  font-weight: $font_weight-regular;
  border: 0;
  width: 100%;
  height: 40px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 1;

  sub {
    font-size: small;
    color: $bg_color--light;
    margin-block-start: 10px;
    margin-block-end: 5px;
  }

  &--high {
    @extend .card_button;
    height: 80px;
  }


  // theme:

  &--primary {
    @extend .card_button;
    color: $font_color--light;
    background-color: $color--primary;

    &:hover, &:active{
      color: $font_color--light;
      background-color: $color--primary-accent;
    }

    p {
        color: $bg_color--light;
        margin-block-start: 0em;
        margin-block-end: 0em;
    }
  }

  &--green {
    color: $font_color--light;
    background-color: $additional_color--green;

    &:hover, &:active{
      color: $font_color--light;
      background-color: $additional_color--green;
    }

    p {
        color: $bg_color--light;
        margin-block-start: 0em;
        margin-block-end: 0em;
    }
  }

  &--orange {
    color: $font_color--light;
    background-color: $additional_color--orange;

    &:hover, &:active{
      color: $font_color--light;
      background-color: $additional_color--orange;
    }

    p {
        color: $bg_color--light;
        margin-block-start: 0em;
        margin-block-end: 0em;
    }
  }

  &--error {
    @extend .card_button;
    color: $font_color--dark;
    background-color: $color--error;

    &:hover, &:active{
      color: $font_color--dark;
      background-color: $color--error-accent;
    }

    p {
        color: $bg_color--dark;
        margin-block-start: 0em;
        margin-block-end: 0em;
    }
  }

  &--disabled {
    @extend .card_button;
    color: $font_color--light;
    background-color: $color--primary-disabled;

    &:hover, &:active{
      color: $font_color--light;
      background-color: $color--primary-disabled;
    }

    p {
        color: $bg_color--light;
        margin-block-start: 0em;
        margin-block-end: 0em;
    }
  }
}



