@use "../../assets/styles/corners" as *;


.ScreenDrawer {
    width: 100%;
    height: 100%;

    img {
        object-fit: contain;
        object-position: left top;
        width: 100%;
        max-width: 100%;
        max-height: 100%;
        height: 100%;
    }

    canvas {
        position: relative;
        // height: 100%;
        // width: 100%;
        image-rendering: pixelated;
    }
}

