@use "../../assets/styles/corners" as *;

@import "../../assets/styles/colors";
@import "../../assets/styles/fonts";


.landing_header_background {
    position: absolute;
    top: 0px;
    z-index: 0;
    width: 100%;
    height: 70px;
    background-color: black;
}

.landing_list {
    z-index: 0;
    transition: filter .3s;

    &--modal {
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 50px;
        margin-bottom: 10px;
        position: absolute;
    }

    &--blurred {
        filter: blur(1.17rem);
        pointer-events: none
    }
    
    &--body {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        
        &-dark {
            @extend .landing_list--body;
            // background-color: black;
            background: rgb(5,56,107);
            background: linear-gradient(0deg, rgba(5,56,107,1) 50%, rgba(13,13,13,1) 100%);
            padding-bottom: 50px;
        }
    }

    &--text {
        font-family: $font_family-montserrat;
        color: $bg_color--light;
        background-color: black;
        padding-top: 10px;
        // margin: 10px;
    
        h1 {
            margin-left: 20px;
            font-size: 30px;
            margin-block-start: 0em;
            margin-block-end: 0em;
        }
    }

    &--card {

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 20px;
        margin-bottom: 10px;
        padding-bottom: 20px;
        width: 95%;
        
        /* From https://css.glass */
        background: rgba(255, 255, 255, 0.23);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        border: 1px solid rgba(255, 255, 255, 0.22);

        h2 {
            color: $bg_color--light;
        }
    }
}


.Landing {
    display: flex;
    flex-direction: column;
    // margin: 20px;
    background-color: white;
}


.Landing-demo-field {
    height: 100vh;
    // width: 100%;
}

.Landing-enter-field {
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}


.Landing-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: black;
    background-color: $bg_color--highlight;
    width: 300px;
    height: 80px;
    @include rounded_medium;
}