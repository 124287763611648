@import "../../assets/styles/colors";

.menu_view {
    background-color: $bg_color--light
}

.header_area {
    z-index: 10;
    position: relative;
}

.projectslist_area {
    // padding-top: 0px;
    // padding-bottom: 100px;
}
