@import "../../assets/styles/colors";
@import "../../assets/styles/fonts";


.label_p {
  margin-block-start: 0px;
}

.label {
  font-family: $font_family-montserrat;
  font-weight: $font_weight-regular;
  border: 0;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  padding: 10px;
  max-width: 300px;

  &--blue {
    @extend .label;
    color: $font_color--dark;
    background-color: $additional_color--light-blue;

    &:hover, &:active{
      color: $font_color--dark;
      background-color: $additional_color--light-blue;
      box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);

    }

    p {
        color: $bg_color--dark;
        margin-block-start: 0em;
        margin-block-end: 0em;
    }
  }

  &--green {
    color: $font_color--dark;
    background-color: $additional_color--light-green;

    &:hover, &:active{
      color: $font_color--dark;
      background-color: $additional_color--light-green;
      box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
    }

    p {
        color: $bg_color--dark;
        margin-block-start: 0em;
        margin-block-end: 0em;
    }
  }

  &--white {
    @extend .label;
    color: $font_color--dark;
    background-color: $bg_color--light;

    &:hover, &:active{
      color: $font_color--dark;
      background-color: $bg_color--light;
      box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
    }

    p {
        color: $font_color--dark;
        margin-block-start: 0em;
        margin-block-end: 0em;
    }
  }

  &--error {
    @extend .label;
    color: $font_color--dark;
    background-color: $color--error;

    &:hover, &:active{
      color: $font_color--dark;
      background-color: $color--error-accent;
    }

    p {
        color: $bg_color--dark;
        margin-block-start: 0em;
        margin-block-end: 0em;
    }
  }

  &--disabled {
    @extend .label;
    color: $font_color--light;
    background-color: $color--primary-disabled;

    &:hover, &:active{
      color: $font_color--light;
      background-color: $color--primary-disabled;
    }

    p {
        color: $bg_color--light;
        margin-block-start: 0em;
        margin-block-end: 0em;
    }
  }
}



